<template>
  <v-dialog scrollable v-model="open_dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">Select payment gateway to use</v-card-title>
      <v-card-text>
        <p>You can select one or more of the payment options available to use as a checkout option on your website.</p>
        <v-select v-model="selected" :items="items" multiple dense outlined chips></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" text @click="closeDialog">Close</v-btn>
        <v-btn class="btn btn-primary" text @click="saveAdditionalGateways">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UserService from "@/services/user/UserService";
const userService = new UserService();

export default {
  name: "additional-gateways",
  props: ["open_dialog", "gateways"],
  data: () => ({
    selected: []
    // items:
  }),
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    country_id() {
      return this.currentUser.country_id;
    },
    items() {
      let items = ["khalti", "esewa", "paypal", "stripe"];
      if (this.country_id === 155) {
        items.push("offline");
      }
      return items;
    }
  },
  mounted() {
    this.selected = this.gateways;
  },
  methods: {
    closeDialog() {
      this.$emit("close_dialog");
    },
    saveAdditionalGateways() {
      let additional_methods = [];
      this.selected.forEach(ele => {
        if (!this.gateways.includes(ele)) {
          additional_methods.push(ele);
        }
      });
      let methods = additional_methods.join(",");
      userService
        .update({ additional_payment_gateways: methods })
        .then(response => {
          this.$snotify.success("Payment gateway saved");
          this.$emit("close_dialog");
        });
    }
  }
};
</script>
