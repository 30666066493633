<template>
    <v-app>
        <v-row>
            <v-col md-6 class="">
                <h3>Payment Gateway Settings</h3>
                <small>Manage Payment gateways on your website checkout</small>
            </v-col>
            <v-col md-6 class="text-right">
                <v-btn
                        class="btn btn-lg btn-add-main mr-2"
                        @click.prevent="openAdditionGatewaysDialog"
                        v-if="!loading"
                >
                    <i class="fas fa-plus"></i>Add Additional Gateways
                </v-btn>
            </v-col>
        </v-row>
        <div class="col-md-12">

            <hr/>

            <div class="row" v-if="loading">
                <div class="col-md-12">
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
                </div>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-left">
                        <p>
                            <img src="/assets/media/payment-options/payment_locked.svg"
                                 class="payment-partner-logo pull-left"/>
                            Affiliated Partner Gateway API for Khalti.
                            <br/>
                            <a href="https://khalti.com">Read more about affiliated payment system.</a>
                        </p>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div class="kt-portlet">
                            <div class="kt-portlet__body">
                                <v-app>
                                    <div
                                            class="row"
                                            v-if="verificationDocs && verificationDocs.submission_status=='verified'"
                                    >
                                        <div class="col-md-12">
                                            <h5>
                                                <i class="fa fa-check-circle text-success"></i> Your account is verified
                                                and active.
                                            </h5>
                                            <p>
                                                Partner payment gateway are active. If you want to use your own payment
                                                gateway API key, simply
                                                add them to the payment
                                                gateway settings below and they will become active.
                                            </p>
                                            <p>Any payment accepted through our Partner Payment gateways will be
                                                processed by Yelko finance team
                                                every week to your nominated bank account outside the system.</p>
                                        </div>
                                    </div>
                                    <div
                                            class="row"
                                            v-else-if="verificationDocs && verificationDocs.submission_status=='pending'"
                                    >
                                        <div class="col-md-12">
                                            <h5>
                                                <i class="fa fa-clock text-primary"></i> Your request for payment
                                                gateway verification is under
                                                review.
                                            </h5>
                                            <p>Payment gateway service will be only available once the documents
                                                submitted is verified &
                                                approved.</p>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-md-12">
                                            <div
                                                    class="col-md-12"
                                                    v-if="verificationDocs && verificationDocs.need_further_info && verificationDocs.submission_status=='rejected'"
                                            >
                                                <h5>
                                                    <i class="fa fa-ban text-danger"></i> Your request for the payment
                                                    gateway verification is
                                                    rejected.
                                                </h5>
                                                <p>
                                                    <strong>Verification Remarks :</strong>
                                                    {{ verificationDocs.further_info_remarks }}
                                                </p>
                                            </div>
                                            <p>We have partnered with Khalti Merchant Facility to facilitate payment
                                                gateway on your website and
                                                invoicing without going through registration process on Khalti Merchant
                                                Facility.</p>
                                            <p>
                                                To use our partner payment gateway under Yelko, please apply to start
                                                your verification process by
                                                submitting your documents.
                                                It may take 2-3 business days to verify your account. If we need further
                                                information to verify, we
                                                will get in touch with you using your mobile or email address provided.
                                            </p>
                                            <p>Once approved, you will be able to use Khalti payment gateway. Payment
                                                will be processed by Yelko
                                                finance team every week to your nominated bank account outside the
                                                system.</p>
                                            <v-col cols="12" md="12"></v-col>
                                            <v-form ref="verification" lazy-validation>
                                                <div class="form-block">
                                                    <v-row>
                                                        <v-col cols="12" md="6">
                                                            <v-file-input
                                                                    v-model="verification.doc_one"
                                                                    accept="image/*, .pdf"
                                                                    type="file"
                                                                    ref="file"
                                                                    placeholder="Upload file"
                                                                    prepend-inner-icon="attach_file"
                                                                    prepend-icon=""
                                                                    label="Company Registration Certificate"
                                                                    outlined
                                                                    dense
                                                            ></v-file-input>
                                                            <p
                                                                    class="text-danger font-weight-bold"
                                                                    v-if="$v.verification.doc_one.$error"
                                                            >File cannot be empty</p>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-file-input
                                                                    v-model="verification.doc_two"
                                                                    accept="image/*, .pdf"
                                                                    type="file"
                                                                    ref="file"
                                                                    placeholder="Upload file"
                                                                    prepend-icon=""
                                                                    prepend-inner-icon="attach_file"
                                                                    label="Tax Clearance Certificate"
                                                                    outlined
                                                                    dense
                                                            ></v-file-input>
                                                            <p
                                                                    class="text-danger font-weight-bold"
                                                                    v-if="$v.verification.doc_two.$error"
                                                            >File cannot be empty</p>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-file-input
                                                                    v-model="verification.doc_three"
                                                                    accept="image/*, .pdf"
                                                                    type="file"
                                                                    ref="file"
                                                                    placeholder="Upload File"
                                                                    prepend-inner-icon="attach_file"
                                                                    prepend-icon=""
                                                                    label="Request Letter"
                                                                    outlined
                                                                    dense
                                                            ></v-file-input>
                                                            <p
                                                                    class="text-danger font-weight-bold"
                                                                    v-if="$v.verification.doc_three.$error"
                                                            >File cannot be empty</p>
                                                        </v-col>

                                                        <v-col cols="12" md="6">
                                                            <v-file-input
                                                                    v-model="verification.doc_four"
                                                                    accept="image/*, .pdf"
                                                                    type="file"
                                                                    ref="file"
                                                                    placeholder="Upload file"
                                                                    prepend-inner-icon="attach_file"
                                                                    prepend-icon=""
                                                                    label="Additional Document"
                                                                    outlined
                                                                    dense
                                                            ></v-file-input>
                                                        </v-col>

                                                        <v-col cols="12" md="6">
                                                            <v-checkbox v-model="acceptPolicy">
                                                                <template v-slot:label>
                                                                    <div>
                                                                        I agree that
                                                                        <span @click="openDialog">
                                    <a href="javascript:void(0)">Terms and Condition</a>
                                  </span>
                                                                    </div>
                                                                </template>
                                                            </v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-form>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    :disabled="!acceptPolicy"
                                                    class="kt-spinner--light kt-spinner--right btn btn-primary "
                                                    @click.prevent="verifyNow"
                                            >Verify & Activate Payment Gateway
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-app>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="gateways.includes('offline')">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <img
                                src="/assets/media/payment-options/payment-gateways/manual-payment-icon.png"
                                class="payment-partner-logo"
                        />
                        <p>Setup manual payment options</p>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div class="kt-portlet">
                            <div class="kt-portlet__body">
                                <div class="row">
                                    <div class="col-md-7">
                                        <p>Manual payment allows you to collect payment offline from the customer.
                                            Offline payments includes
                                            Cash on Delivery, Bank Deposit, Wire Transfer or Pay on Invoice.</p>
                                        <b-form class="kt-form">
                                            <v-switch
                                                    v-model="manualPayement.is_active"
                                                    label="Status"
                                            ></v-switch>
                                            <div v-if="manualPayement.is_active">
                                                <b-input-group class="mr-sm-2 mb-sm-0 m-2">
                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            v-model="manualPayement.title"
                                                            placeholder="Checkout Name. Eg: Cash on Delivery"
                                                            name="example-input-0"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                                <b-input-group class="m-2">
                                                    <b-form-textarea
                                                            id="textarea"
                                                            v-model="manualPayement.note"
                                                            placeholder="Enter instructions for payment..."
                                                            rows="3"
                                                            max-rows="6"
                                                            class="border"
                                                    ></b-form-textarea>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid API
                                                        key.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                                <br/>
                                                <v-button
                                                        @click="savePaymentGateways('manual')"
                                                        id="kt_submit_free"
                                                        class="btn btn-primary kt-spinner--light kt-spinner--right"
                                                >Save
                                                </v-button>
                                            </div>
                                        </b-form>
                                    </div>
                                    <div class="col-md-5 p-5">
                                        <div class="payment_details">
                                            <img src="/assets/media/payment-options/payment-gateways/cash-payment.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/bank-deposit-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/invoicing-icon.png"/>
                                            <hr/>
                                            <h6>Supported payment</h6>
                                            <ul>
                                                <li>Cash on Delivery</li>
                                                <li>Bank Transfer</li>
                                                <li>Gift Credits</li>
                                                <li>Invoicing</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="gateways.includes('khalti')">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <img
                                src="/assets/media/payment-options/payment-gateways/khalti-logo.png"
                                class="payment-partner-logo"
                        />
                        <p>
                            For more details on stripe merchant registration, visit
                            <a
                                    href="https://khalti.com"
                            >Visit Khalti Website</a>
                        </p>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div class="kt-portlet">
                            <div class="kt-portlet__body">
                                <div class="row">
                                    <div class="col-md-7">
                                        <p>
                                            Khalti is a mobile wallet, payment gateway & service provider in Nepal.
                                            Launched in January 2017,
                                            Khalti is an emerging online payment solution in Nepal.
                                            It allows users to pay for a range of services like basic utility payments,
                                            hotel bookings, movie
                                            and domestic flight tickets, events and many more.
                                            It is operated and owned by Sparrow Pay Pvt. Ltd.
                                        </p>
                                        <div class="kt-form" v-if="showKhalti">
                                            <v-switch
                                                    v-model="khalti.is_active"
                                                    label="Status"
                                            ></v-switch>
                                            <v-switch
                                                    v-model="khalti.is_sandbox"
                                                    label="Sandbox"
                                            ></v-switch>
                                            <div v-if="khalti.is_active">
                                                <b-input-group class="mr-sm-2 mb-sm-0 m-2">
                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            placeholder="Khalti Live Public Key"
                                                            name="example-input-0"
                                                            v-model="khalti.public_key"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid API
                                                        key.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                                <p
                                                        class="text-danger font-weight-bold"
                                                        v-if="$v.khalti.public_key.$error"
                                                >Public Key is required</p>
                                                <b-input-group class="m-2">
                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            placeholder="Khalti Live Private Key"
                                                            name="example-input-0"
                                                            v-model="khalti.private_key"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid API
                                                        key.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                                <p
                                                        class="text-danger font-weight-bold"
                                                        v-if="$v.khalti.private_key.$error"
                                                >Private Key is required</p>
                                                <br/>
                                                <v-button
                                                        @click="savePaymentGateways('khalti')"
                                                        id="kt_submit_free"
                                                        class="btn btn-primary kt-spinner--light kt-spinner--right"
                                                >Save
                                                </v-button>
                                            </div>
                                        </div>
                                        <div class="text-center mt-6" v-else>
                                            <img src="@/assets/media/icons/secure.png" width="50px"/>
                                            <a
                                                    href="javascript:void(0)"
                                                    @click.once="showPaymentGateways('khalti')"
                                            >Click here to edit</a>
                                        </div>
                                    </div>
                                    <div class="col-md-5 p-5">
                                        <div class="payment_details">
                                            <img src="/assets/media/payment-options/payment-gateways/wallet-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/bank-deposit-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/invoicing-icon.png"/>
                                            <hr/>
                                            <h6>Supported payment</h6>
                                            <ul>
                                                <li>Digital Wallet</li>
                                                <li>Load fund through bank</li>
                                                <li>Load fund via credit/debit card</li>
                                                <li>Invoicing</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="gateways.includes('esewa')">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <img
                                src="/assets/media/payment-options/payment-gateways/esewa-logo.png"
                                class="payment-partner-logo"
                        />
                        <p>
                            For more details on stripe merchant registration, visit
                            <a
                                    href="https://esewa.com.np"
                            >Visit Esewa Website</a>
                        </p>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div class="kt-portlet">
                            <div class="kt-portlet__body">
                                <div class="row">
                                    <div class="col-md-7">
                                        <p>
                                            eSewa – Nepal’s first online payment gateway is a comprehensive online
                                            payment solution.
                                            With eSewa digital wallet, customers can pay, send and receive money simply
                                            using their mobile phone
                                            and the Internet instantly.
                                            We have been operation since 2009 and have been licensed by Nepal Rastra
                                            Bank as Payment Service
                                            Provider.
                                        </p>
                                        <b-form class="kt-form" v-if="showEsewa">
                                            <v-switch
                                                    v-model="esewa.is_active"
                                                    label="Status"
                                            ></v-switch>
                                            <v-switch
                                                    v-model="esewa.is_sandbox"
                                                    label="Sandbox"
                                            ></v-switch>
                                            <div v-if="esewa.is_active">
                                                <b-input-group class="mr-sm-2 mb-sm-0 m-2">
                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            placeholder="Esewa Merchant Code"
                                                            v-model="esewa.merchant_id"
                                                            name="example-input-0"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid merchant
                                                        Code.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                              <p
                                                      class="text-danger font-weight-bold"
                                                      v-if="$v.esewa.merchant_id.$error"
                                              >Merchant Key is required</p>

                                                <br/>
                                                <v-button
                                                        @click="savePaymentGateways('esewa')"
                                                        id="kt_submit_free"
                                                        class="btn btn-primary kt-spinner--light kt-spinner--right"
                                                >Save
                                                </v-button>
                                            </div>
                                        </b-form>
                                        <div class="text-center mt-6" v-else>
                                            <img src="@/assets/media/icons/secure.png" width="50px"/>
                                            <a
                                                    href="javascript:void(0)"
                                                    @click.once="showPaymentGateways('esewa')"
                                            >Click here to edit</a>
                                        </div>
                                    </div>
                                    <div class="col-md-5 p-5">
                                        <div class="payment_details">
                                            <img src="/assets/media/payment-options/payment-gateways/wallet-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/bank-deposit-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/invoicing-icon.png"/>
                                            <hr/>
                                            <h6>Supported payment</h6>
                                            <ul>
                                                <li>Digital Wallet</li>
                                                <li>Load fund through bank</li>
                                                <li>Load fund via credit/debit card</li>
                                                <li>Invoicing</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="gateways.includes('paypal')">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <img
                                src="/assets/media/payment-options/payment-gateways/paypal-logo.png"
                                class="payment-partner-logo"
                        />
                        <p>
                            For more details on paypal business registration, visit
                            <a
                                    href="https://paypal.com"
                            >Visit Paypal Website</a>
                        </p>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div class="kt-portlet">
                            <div class="kt-portlet__body">
                                <div class="row">
                                    <div class="col-md-7">
                                        <p>To start accepting payments, enter the email address where your payments
                                            should be sent. This email
                                            address should be the same one associated with your PayPal account. If you
                                            don’t have a PayPal
                                            account, you can set it up later using the same email address that you’ve
                                            provided here.</p>
                                        <b-form class="kt-form" v-if="showPaypal">
                                            <v-switch
                                                    v-model="paypal.is_active"
                                                    label="Status"
                                            ></v-switch>
                                            <v-switch
                                                    v-model="paypal.is_sandbox"
                                                    label="Sandbox"
                                            ></v-switch>
                                            <div class="" v-if="paypal.is_active">
                                                <b-input-group class="mr-sm-2 mb-sm-0 m-2">
                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            placeholder="Paypal Live Public Key"
                                                            name="example-input-0"
                                                            v-model="paypal.public_key"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid API
                                                        key.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                              <p
                                                      class="text-danger font-weight-bold"
                                                      v-if="$v.paypal.public_key.$error"
                                              >Public Key is required</p>

                                              <b-input-group class="m-2">
                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            placeholder="Paypal Live Private Key"
                                                            v-model="paypal.private_key"
                                                            name="example-input-0"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid API
                                                        key.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                              <p
                                                      class="text-danger font-weight-bold"
                                                      v-if="$v.paypal.private_key.$error"
                                              >Private Key is required</p>
                                                <br/>
                                                <v-button
                                                        @click="savePaymentGateways('paypal')"
                                                        id="kt_submit_free"
                                                        class="btn btn-primary kt-spinner--light kt-spinner--right"
                                                >Save
                                                </v-button>
                                            </div>
                                        </b-form>
                                        <div class="text-center mt-6" v-else>
                                            <img src="@/assets/media/icons/secure.png" width="50px"/>
                                            <a
                                                    href="javascript:void(0)"
                                                    @click.once="showPaymentGateways('paypal')"
                                            >Click here to edit</a>
                                        </div>
                                    </div>
                                    <div class="col-md-5 p-5">
                                        <div class="payment_details">
                                            <img src="/assets/media/payment-options/payment-gateways/paypal-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/visa-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/mastercard-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/invoicing-icon.png"/>
                                            <hr/>
                                            <h6>Supported payment</h6>
                                            <ul>
                                                <li>Credit & Debit Card</li>
                                                <li>Apple Pay</li>
                                                <li>Invoicing</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="gateways.includes('stripe')">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <img
                                src="/assets/media/payment-options/payment-gateways/stripe-logo.png"
                                class="payment-partner-logo"
                        />
                        <p>
                            For more details on stripe merchant registration, visit
                            <a
                                    href="https://stripe.com"
                            >Stripe Website</a>
                        </p>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div class="kt-portlet">
                            <div class="kt-portlet__body">
                                <div class="row">
                                    <div class="col-md-7">
                                        <p>
                                            Stripe is the easiest way to accept credit cards. Process major
                                            international debit or credit cards,
                                            including Visa, MasterCard and American Express.
                                            You don’t need a merchant account, so you can start accepting payments
                                            today.
                                        </p>
                                        <p>Click "Connect with Stripe", create a free Stripe account in a few minutes
                                            and start processing
                                            payments immediately.</p>
                                        <p>By enabling payments through Stripe, you will be accepting Stripe Services
                                            Agreement. Please refer
                                            to the list of Prohibited Businesses before registering for and opening a
                                            Stripe account.</p>
                                        <b-form class="kt-form" v-if="showStripe">
                                            <v-switch
                                                    v-model="stripe.is_active"
                                                    label="Status"
                                            ></v-switch>
                                            <v-switch
                                                    v-model="stripe.is_sandbox"
                                                    label="Sandbox"
                                            ></v-switch>
                                            <div class="" v-if="stripe.is_active">
                                                <b-input-group class="mr-sm-2 mb-sm-0 m-2">

                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            placeholder="Stripe Live Public Key"
                                                            v-model="stripe.public_key"
                                                            name="example-input-0"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid API
                                                        key.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                              <p
                                                      class="text-danger font-weight-bold"
                                                      v-if="$v.stripe.public_key.$error"
                                              >Public Key is required</p>
                                                <b-input-group class="m-2">
                                                    <b-input
                                                            id="inline-form-input-free-domain"
                                                            placeholder="Stripe Live Private Key"
                                                            v-model="stripe.private_key"
                                                            name="example-input-0"
                                                            class="border"
                                                    ></b-input>
                                                    <b-form-invalid-feedback id="input-0-live-feedback">Invalid API
                                                        key.
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                              <p
                                                      class="text-danger font-weight-bold"
                                                      v-if="$v.stripe.private_key.$error"
                                              >Private Key is required</p>
                                                <br/>
                                                <v-button
                                                        @click="savePaymentGateways('stripe')"
                                                        id="kt_submit_free"
                                                        text
                                                        class="btn btn-primary kt-spinner--light kt-spinner--right"
                                                >Save
                                                </v-button>
                                            </div>
                                        </b-form>
                                        <div class="text-center mt-6" v-else>
                                            <img src="@/assets/media/icons/secure.png" width="50px"/>
                                            <a
                                                    href="javascript:void(0)"
                                                    @click.once="showPaymentGateways('stripe')"
                                            >Click here to edit</a>
                                        </div>
                                    </div>
                                    <div class="col-md-5 p-5">

                                        <div class="payment_details">
                                            <img src="/assets/media/payment-options/payment-gateways/visa-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/mastercard-icon.png"/>
                                            <img src="/assets/media/payment-options/payment-gateways/invoicing-icon.png"/>
                                            <hr/>
                                            <h6>Supported payment</h6>
                                            <ul>
                                                <li>Credit & Debit Card</li>
                                                <li>Apple Pay</li>
                                                <li>Invoicing</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <v-dialog scrollable v-model="policyDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">Use Yelko's Payment Options?</v-card-title>
                <v-card-text>Let Google help apps determine location. This means sending anonymous location data to
                    Google, even
                    when no apps are running.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeDialog">Disagree</v-btn>
                    <v-btn color="green darken-1" text @click="aggreePolicy">Agree</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <additional-gateways
                v-if="gateways.length>0"
                :open_dialog="open_additional_dialog"
                :gateways="gateways"
                @close_dialog="closeAdditionGatewaysDialog"
        ></additional-gateways>
    </v-app>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import PayementgatewayService from "@/services/store/PayementgatewayService";
    import PayementgatewayVerificationService from "@/services/store/PayementgatewayVerificationService";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import CountryService from "@/services/address/CountryService";
    import AdditionalGateways from "./AdditionalGateways";

    const country = new CountryService();
    const Payementgateway = new PayementgatewayService();
    const PayementgatewayVerfication = new PayementgatewayVerificationService();

    export default {
        name: "store-payment-gateway",
        components: {
            KTPortlet,
            AdditionalGateways
        },
        validations: {
            verification: {
                doc_one: {required},
                doc_two: {required},
                doc_three: {required}
            },
            khalti: {
                public_key: {required},
                private_key: {required},
            },
            esewa: {
                merchant_id: {required},
            },
            paypal: {
                public_key: {required},
                private_key: {required},
            },
            stripe: {
                public_key: {required},
                private_key: {required},
            },
        },
        data() {
            return {
                open_additional_dialog: false,
                loading: true,
                verification: {
                    doc_one: null,
                    doc_two: null,
                    doc_three: null,
                    doc_four: null
                },
                gateways: [],
                acceptPolicy: false,
                policyDialog: false,
                verificationDocs: null,
                showKhalti: true,
                showEsewa: true,
                showPaypal: true,
                showStripe: true,

                khalti: {
                    id: null,
                    type: "khalti",
                    public_key: null,
                    private_key: null,
                    commission: null,
                    return_url: null,
                    title: null,
                    merchant_id: null,
                    note: null
                },
                esewa: {
                    id: null,
                    type: "esewa",
                    public_key: null,
                    private_key: null,
                    commission: null,
                    return_url: null,
                    title: null,
                    merchant_id: null,
                    note: null
                },
                paypal: {
                    id: null,
                    type: "paypal",
                    public_key: null,
                    private_key: null,
                    commission: null,
                    return_url: null,
                    title: null,
                    merchant_id: null,
                    note: null
                },
                stripe: {
                    id: null,
                    type: "stripe",
                    public_key: null,
                    private_key: null,
                    commission: null,
                    return_url: null,
                    title: null,
                    merchant_id: null,
                    note: null
                },
                manualPayement: {
                    id: null,
                    type: "cash",
                    public_key: null,
                    private_key: null,
                    commission: null,
                    return_url: null,
                    title: null,
                    merchant_id: null,
                    note: null
                }
            };
        },
        methods: {
            openAdditionGatewaysDialog() {
                this.open_additional_dialog = true;
            },
            closeAdditionGatewaysDialog() {
                this.open_additional_dialog = false;
                this.availablePaymentMethods();
                this.getPaymentGateways();
            },
            availablePaymentMethods() {
                country.getAvailablePaymentMethodsByUser().then(response => {
                    this.gateways = response.data;
                    this.loading = false;
                });
            },
            openDialog() {
                this.policyDialog = true;
            },
            closeDialog() {
                this.policyDialog = false;
            },
            verifyNow() {
                this.$v.verification.$touch();
                if (this.$v.verification.$error) {
                    setTimeout(() => {
                        this.$v.verification.$reset();
                    }, 3000);
                } else {
                    let formData = new FormData();
                    formData.append(
                        "doc_one",
                        this.verification.doc_one ? this.verification.doc_one : ""
                    );
                    formData.append(
                        "doc_two",
                        this.verification.doc_two ? this.verification.doc_two : ""
                    );
                    formData.append(
                        "doc_three",
                        this.verification.doc_three ? this.verification.doc_three : ""
                    );
                    formData.append(
                        "doc_four",
                        this.verification.doc_four ? this.verification.doc_four : ""
                    );

                    if (!this.verificationDocs) {
                        this.createVerification(formData);
                    } else {
                        formData.append("submission_status", "pending");
                        formData.append("need_further_info", 0);
                        this.updateVerifiation(formData);
                    }
                }
            },
            createVerification(formData) {
                PayementgatewayVerfication.create(formData)
                    .then(() => {
                        this.$snotify.success("Your verification request has been submitted");
                        this.getPaymentGatewaysVerifications();
                        this.loading = false;
                    })
                    .catch(error => {
                    });
            },
            updateVerifiation(formData) {
                PayementgatewayVerfication.update(this.verificationDocs.id, formData)
                    .then(() => {
                        this.$snotify.success("Your verification request has been submitted");
                        this.getPaymentGatewaysVerifications();
                        this.loading = false;
                    })
                    .catch(error => {
                    });
            },
            aggreePolicy() {
                this.acceptPolicy = true;
                this.policyDialog = false;
            },
            savePaymentGateways(type) {
                switch (type) {
                    case "manual":
                        this.savePayement(this.manualPayement);
                        break;
                    case "khalti":
                        this.$v.khalti.$touch();
                        if (this.$v.khalti.$error) {
                            setTimeout(() => {
                                this.$v.khalti.$reset();
                            }, 3000);
                        } else {
                            this.savePayement(this.khalti);
                        }
                        break;
                    case "esewa":
                      this.$v.esewa.$touch();
                      if (this.$v.esewa.$error) {
                        setTimeout(() => {
                          this.$v.esewa.$reset();
                        }, 3000);
                      } else {
                        this.savePayement(this.esewa);
                      }
                        break;
                    case "paypal":
                      this.$v.paypal.$touch();
                      if (this.$v.paypal.$error) {
                        setTimeout(() => {
                          this.$v.paypal.$reset();
                        }, 3000);
                      } else {
                        this.savePayement(this.paypal);
                      }
                        break;
                    case "stripe":
                      this.$v.stripe.$touch();
                      if (this.$v.stripe.$error) {
                        setTimeout(() => {
                          this.$v.stripe.$reset();
                        }, 3000);
                      } else {
                        this.savePayement(this.stripe);
                      }
                        break;

                    default:
                        break;
                }
            },
            showPaymentGateways(type) {
                switch (type) {
                    case "khalti":
                        this.khalti.private_key = "";
                        this.khalti.public_key = "";
                        this.showKhalti = true;
                        break;
                    case "esewa":
                        this.esewa.merchant_id = "";
                        this.showEsewa = true;
                        break;
                    case "paypal":
                        this.paypal.private_key = "";
                        this.paypal.public_key = "";
                        this.showPaypal = true;
                        break;
                    case "stripe":
                        this.stripe.private_key = "";
                        this.stripe.public_key = "";
                        this.showStripe = true;
                        break;
                    default:
                        break;
                }
            },
            savePayement(payment) {
                if (payment.id == null) {
                    Payementgateway.create(payment).then(res => {
                        this.$snotify.success("Payment gateway updated");
                        this.getPaymentGateways();
                    });
                } else {
                    Payementgateway.update(payment.id, payment).then(res => {
                        this.$snotify.success("Payment gateway updated");
                        this.getPaymentGateways();
                    });
                }
            },
            getPaymentGatewaysVerifications() {
                PayementgatewayVerfication.show().then(res => {
                    res.data ? (this.verificationDocs = res.data) : false;
                });
            },

            getPaymentGateways() {
                Payementgateway.paginate().then(res => {
                    if (res.data.khalti) {
                        this.khalti = res.data.khalti;
                        this.showKhalti = false;
                        if (this.khalti.is_sandbox) this.showKhalti = true;
                    }
                    if (res.data.esewa) {
                        this.esewa = res.data.esewa;
                        this.showEsewa = false;
                        if (this.esewa.is_sandbox) this.showEsewa = true;
                    }
                    if (res.data.stripe) {
                        this.stripe = res.data.stripe;
                        this.showStripe = false;
                        if (this.stripe.is_sandbox) this.showStripe = true;
                    }
                    if (res.data.paypal) {
                        this.paypal = res.data.paypal;
                        this.showPaypal = false;
                        if (this.paypal.is_sandbox) this.showPaypal = true;
                    }
                    if (res.data.cash) this.manualPayement = res.data.cash;
                });
            },
            toogleActive(payment) {
                // payment.is_active = !payment.is_active;
                Payementgateway.update(payment.id, payment).then(res => {
                    this.$snotify.success("payment gateways updated");
                    this.getPaymentGateways();
                });
            },
            activateSanbox(payment) {
                payment.private_key = null
                payment.public_key = null
                payment.merchant_id = null
                Payementgateway.update(payment.id, payment).then(res => {
                    this.$snotify.success("Payment gateway updated");
                    this.getPaymentGateways();
                });
            }
        },

        computed: {},
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Manage Products", route: "/websites"}
            ]);
            this.getPaymentGateways();
            this.getPaymentGatewaysVerifications();
            this.availablePaymentMethods();
        }
    };
</script>
<style scoped>
    #preview {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #preview img {
        max-width: 100%;
        max-height: 350px;
    }

    .logo-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .logo-wrapper > span {
        display: block;
        flex: 1;
        margin-left: 15px;
    }

    .logo-wrapper .v-image {
        flex: none;
    }
</style>
