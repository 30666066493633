import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class PayementgatewayVerificationService {


    paginate(index = null) {
        let url = API_URL + 'user/verification/payment-gateway';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    create(data) {
        let url = API_URL + 'user/verification/payment-gateway';
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = API_URL + 'user/verification/payment-gateway/' + id + '/update'
        return apiService.post(url, data);
    }

    delete(id) {
        let url = API_URL + 'user/verification/payment-gateway/' + id
        return apiService.delete(url);
    }
    show() {
        let url = API_URL + 'user/verification/payment-gateway'
        return apiService.get(url);
    }


}
